<template>
  <li>
    <item-card>
      <template v-slot:content>
        <div class="flex flex-wrap lg:flex-nowrap w-full">
          <template v-if="editableItem.id !== item.id">

            <div class="grid grid-cols-12 w-full">
                <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-3">
                    <item-text title="Nosaukums" :text="item.name" />
                </div>
                <div class="col-span-12 sm:col-span-6 lg:col-span-2">
                    <item-text title="Datums" :text="item.date" />
                </div>
                <div class="col-span-12 sm:col-span-6 lg:col-span-3">
                    <item-text title="Notikums" :text="item.event" />
                </div>

                <template v-if="item.company">
                  <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
                      <item-text title="Kompānija" :text="item.company" />
                  </div>
                </template>
            </div>

          </template>

          <div class="ml-auto flex items-center">
            <template v-if="editableItem.id !== item.id">

              <Button icon="pencil" @click="editItem(item)" />

              <Button icon="delete" @click="deleteBank(item.id)" />

            </template>
          </div>

        </div>
      </template>

      <template v-slot:additional>
        <template v-if="editableItem.id === item.id">
          <EditCelebration :item="item"/>
        </template>
      </template>

    </item-card>
  </li>
</template>

<script>
import {mapGetters} from "vuex"
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"
import EditCelebration from "@/components/Settings/Celebrations/EditCelebration"

export default {
  name: "SingleCelebration",
  components: {
    ItemCard,
    ItemText,
    EditCelebration,
  },
  props: ['item'],
  computed: {
    ...mapGetters({
      editableItem: 'editableItem'
    })
  },
  methods: {
    editItem(item){
      this.$store.dispatch('setEditableItem', item)
    },
    deleteBank(itemId){
      if(confirm('Vai esi drošs?')){
        this.$Progress.start()
        this.$store.dispatch('deleteCelebration', itemId)
      }
    }
  }
}
</script>
