<template>
  <li>
    <item-card :url="`/roles/${role.id}`" contentClass="grid grid-cols-12">
      <template v-slot:content>
        
        <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3">
          <item-text title="Loma" :text="role.display_name" />
        </div>
        <div class="col-span-12 sm:col-span-6">
          <item-text title="Atļaujas" :text="role.permissions.length" />
        </div>

      </template>
    </item-card>
  </li>
</template>

<script>
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"

export default {
  name: "RolesListItem",
  components: {
    ItemCard,
    ItemText,
  },
  props: {
    role: {
      type: Object,
      required: true,
    }
  },
}
</script>
