<template>
    <Content>
        <template v-slot:topbar>
            <AddCelebration />
        </template>
        <template v-slot:content>
            <template v-if="celebrations && celebrations.data && celebrations.data.length">
                <div class="sm:rounded-md mb-3">
                    <ul role="list" class="">
                        <template v-for="item in celebrations.data" :key="item.id">
                            <SingleCelebration :item="item" />
                        </template>
                    </ul>
                </div>

                <Pagination :meta="celebrations" :onPageChange="onPageChange" />
            </template>

            <template v-else>
                <p class="py-6 text-center dark:text-gray-300">
                    Diemžēl nekas netika atrasts
                </p>
            </template>
        </template>
    </Content>
</template>

<script>
import { debounce } from 'vue-debounce'
import {mapGetters} from "vuex";
import Search from "@/services/Helpers/search";
import AddCelebration from "@/components/Settings/Celebrations/AddCelebration"
import SingleCelebration from "@/components/Settings/Celebrations/SingleCelebration"
import Pagination from "@/components/Components/Pagination";

export default {
    name: "Celebrations",
    components: {
        AddCelebration,
        SingleCelebration,
        Pagination,
    },
    created() {
        this.$store.dispatch("setDataLoading", true)
    },
    mounted() {
        this.$store.dispatch('removeAllFormsForDisplay')
        this.$store.dispatch('resetSearch')
        const s = Search.getSearchQuery();

        if (s) {
            this.$store.dispatch('changeSearch', s)
            this.searchCelebrations()
        } else {
            this.$store.dispatch('getCelebrations', Search.getCurrentPage())
        }
    },
    computed: {
        ...mapGetters({
            celebrations: 'celebrations',
            formsForDisplay: 'formsForDisplay',
            editableItem: 'editableItem',
            search: 'search',
        })
    },
    watch: {
        search: function () {
            this.searchCelebrations()
        }
    },
    methods: {
        searchCelebrations: debounce(function () {
            this.$store.dispatch('changeSearch', this.search)
            this.$store.dispatch('getCelebrations', Search.getCurrentPage())
        }, 500),
        onPageChange(page) {
            this.$store.dispatch('getCelebrations', page);
            Search.changePage(this.celebrations.current_page)
        },
        showForm(formName){
            this.$store.dispatch('addFormForDisplay', formName)
        },
        displayForm(formName){
            return this.formsForDisplay.includes(formName)
        },
        hideAddForm(){
            this.$store.dispatch('removeAllFormsForDisplay')
        },
    }
}
</script>