<template>
  <Modal :size="'md'" :showModal="showModal">
    <template v-slot:button>
      <div class="flex h-full items-center">
        <button
          @click="showModal = true"
          class="rounded-full w-12 h-12 bg-gray-200 dark:bg-main4 flex justify-center items-center leading-none hover:bg-gray-300 transition duration-300 ease-in-out dark:text-gray-100"
        >
          <PlusIcon class="w-7 h-7" />
        </button>
      </div>
    </template>

    <template v-slot:content>
      <form class="space-y-6 w-full">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div class="col-span-1">
                    <Input v-model="form.name" :errors="errors.name" placeholder="Nosaukums" />
                </div>

                <div class="col-span-1">
                    <Input v-model="form.event" :errors="errors.event" placeholder="Notikums" />
                </div>

                <div class="col-span-1">
                    <Input v-model="form.company" :errors="errors.company" placeholder="Kompānija" />
                </div>

                <div class="col-span-1">
                    <div class="flex w-full gap-6">
                        <template v-if="months">
                            <div class="flex-1">
                                <Select v-model="form.date_month" :items="months" :errors="errors.date_month" placeholder="Mēnesis" />
                            </div>

                            <template v-if="dates">
                                <div class="flex-1">
                                    <Select v-model="form.date_date" :items="dates" :errors="errors.date_date" placeholder="Datums" />
                                </div>
                            </template>
                        </template>
                    </div>
                </div>

            </div>

        <div class="">
          <div class="flex justify-end">
            <button
              type="button"
              class="bg-white dark:bg-gray-750 py-2 px-4 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 mr-2"
              @click="hideAddItemForm"
            >
              Atcelt
            </button>

            <template v-if="!loading">
              <button
                type="button"
                class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out"
                @click.prevent="submit"
              >
                Pievienot
              </button>
            </template>
            <template v-else>
              <Loading />
            </template>
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import moment from "moment"
import 'moment/locale/lv'
moment.locale('lv')

import { mapGetters } from "vuex";
import Modal from "@/components/Components/Modal";
import Input from "@/components/Components/Input";
import Select from "@/components/Components/Select";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";
import {
  PlusIcon,
} from "@heroicons/vue/solid";

export default {
    name: "AddCelebration",
    components: {
        Modal,
        Input,
        Select,
        Loading,
        PlusIcon,
    },
    data: () => ({
        form: {
            date_month: null,
            date_date: null,
            name: null,
            event: null,
            company: null,
        },
        months: null,
        dates: null,
        showModal: false,
    }),
    watch: {
        showModal() {
            this.form = {
                date_month: null,
                date_date: null,
                name: null,
                event: null,
                company: null,
            };

            const month = moment().format('MMMM')
            this.form.date_month = {id: moment().format('M'), name: month.charAt(0).toUpperCase() + month.slice(1)}
            this.form.date_date = {id: moment().format('D'), name: moment().format('D')}
            this.dates = this.getAllMonthDates()
        },
        'form.date_month': function() {
            this.dates = this.getAllMonthDates()
        }
    },
    mounted() {
        this.months = moment.months().map((month, index) => {
            return {
                id: index + 1,
                name: month.charAt(0).toUpperCase() + month.slice(1)
            }
        })
    },
    computed: {
        ...mapGetters({
            loading: "loading",
            errors: "errors",
        }),
        formValidation() {
            return {
                name: {
                    rules: ['required']
                },
                date_month: {
                    rules: ['required']
                },
                date_date: {
                    rules: ['required']
                },
                event: {
                    rules: ['required']
                },
            }
        },
    },
    methods: {
        hideAddItemForm() {
            this.$store.dispatch("removeAllFormsForDisplay");
            this.showModal = false;
        },

        getAllMonthDates() {
            var monthDate = moment(2024 + '-' + this.form.date_month.id, 'YYYY-M');
            var daysInMonth = monthDate.daysInMonth();
            let dates = Array.from({ length: daysInMonth }, (_, i) => i + 1);

            dates = dates.map(date => {
                return {
                    id: date,
                    name: date,
                }
            })

            return dates;      
        },
        getDate() {
            const month = moment(this.form.date_month.name, 'MMMM').format('MM')
            return `${this.form.date_date.id}.${month}.`
        },

        submit() {
            this.$Progress.start();
            if (Validation(this.formValidation, this.form)) {
                this.$store
                .dispatch("createNewCelebration", {
                    date: this.getDate(),
                    name: this.form.name,
                    event: this.form.event,
                    company: this.form.company
                })
                .then(() => {
                    this.showModal = false;
                });
            } else {
                this.$Progress.fail();
            }
        },
    },
};
</script>