<template>
  <Content :search="false">
    <template v-slot:topbar>
      <div class="w-full flex items-center justify-end">
        <AddRole />
      </div>
    </template>

    <template v-slot:content>
      <template v-if="roles && roles.data.length > 0">
        <div class="sm:rounded-md mb-3">
          <ul role="list" class="">
            <template v-for="role in roles.data" :key="role.id">
              <RolesListItem :role="role" />
            </template>
          </ul>
        </div>

        <Pagination :meta="roles.meta" :onPageChange="onPageChange" />
      </template>

      <template v-else>
        <p class="py-6 text-center dark:text-gray-300">
          Diemžēl nekas netika atrasts
        </p>
      </template>
    </template>
  </Content>
</template>

<script>
import { debounce } from 'vue-debounce'
import {mapGetters} from "vuex";
import Search from "@/services/Helpers/search";
import AddRole from "@/components/Roles/AddRole";
import RolesListItem from "@/components/Roles/RolesListItem";
import Pagination from "@/components/Components/Pagination";

export default {
  name: "Roles",
  components: {
    AddRole,
    RolesListItem,
    Pagination,
  },
  created() {
    this.$store.dispatch("setDataLoading", true)
  },
  mounted() {
    this.$store.dispatch('removeAllFormsForDisplay')
    this.$store.dispatch('resetSearch')
    this.$store.dispatch('getRoles', 1)
  },
  computed: {
    ...mapGetters({
      roles: 'roles',
      formsForDisplay: 'formsForDisplay',
      search: 'search',
    })
  },
  watch: {
    search: function () {
      this.searchRoles()
    }
  },
  methods: {
    searchRoles: debounce(function () {
      this.$store.dispatch('changeSearch', this.search)
      this.$store.dispatch('getRoles', Search.getCurrentPage())
    }, 500),
    onPageChange(page) {
      this.$store.dispatch('getRoles', page);
    },
    showForm(formName) {
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName) {
      return this.formsForDisplay.includes(formName)
    },
    hideAddRoleForm() {
      this.$store.dispatch('removeAllFormsForDisplay')
    },
    viewRole(roleId) {
      this.$router.push({path: '/roles/' + roleId})
    },
  }
}
</script>